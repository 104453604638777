import React, { createContext, useState, useMemo, useContext } from 'react';

const VerifyCounterContext = createContext();

export function VerifyCounterProvider(props) {
  const [verifyCounter, setVerifyCounter] = useState(null);
  const providerVerifyCounter = useMemo(() => ({ verifyCounter, setVerifyCounter }), [verifyCounter]);

  return (
    <VerifyCounterContext.Provider
      value={providerVerifyCounter}
      {...props}
    ></VerifyCounterContext.Provider>
  );
}

export function useVerifyCounter() {
  const context = useContext(VerifyCounterContext);
  return context;
}
