import React, { createContext, useState, useMemo, useContext } from 'react';

const RPContext = createContext();

export function RPProvider(props) {
  const [subdomain, setSubdomain] = useState(undefined);
  const [buttonStyles, setButtonStyles] = useState({});

  const providerSubdomain = useMemo(() => (
    { subdomain   , setSubdomain 
    , buttonStyles, setButtonStyles
    }), [subdomain, buttonStyles]);

  return (
    <RPContext.Provider
      value={providerSubdomain}
      {...props}
    ></RPContext.Provider>
  );
}

export function useRP() {
  return useContext(RPContext);
}
