import React, { useState } from 'react';
import { IonAlert, IonButton, IonContent, IonPage, IonText } from '@ionic/react';
import { useHistory } from 'react-router-dom';

//Styles
import styles from './ResetAccount.module.css';

//Components
import Header from '../../layout/Header/Header';

//Utils
import { logout } from '../../../utils/session';
import { deleteIdgoAccount } from '../../../utils/services';
import { useToast } from '@agney/ir-toast';
import logger from '../../../utils/logger';

//Context
import { useLogin } from '../../../context/LoginContext';
import { useRP } from '../../../context/RPContext';

function ResetAccount() {
  const history = useHistory();
  const [showAlert, setShowAlert] = useState(false);
  const Toast = useToast();
  const { setLoginValue } = useLogin();
  const { setSubdomain } = useRP();

  const handleAlert = () => {
    setShowAlert(true);
  };

  const handleReset = async () => {
    const response = await deleteIdgoAccount();
    if (response && response.status === 200) {
      logger.event('reset-account');
      setLoginValue('');
      setSubdomain(undefined);
      logout();
      Toast.create({message: 'Success!', color: 'success', duration: 2000, position: 'top'}).present();
      setTimeout(() => {
        history.replace('/');
      }, 1000);
    } else {
      logger.warn(response);
      setShowAlert(false);
      Toast.create({message: 'An error ocurred. Please try again later', color: 'danger', duration: 2000, position: 'top'}).present();
    }
    return;
  };

  return (
    <IonPage>
      <Header page='Delete Account' />
      <IonContent>
        <div className={styles.Container}>
          <IonText className={styles.ContainerText} data-testid='reset-account-paragraph'>
            <p>If you delete your account,<br /> please keep the following in mind:</p>
          </IonText>
          <ul className={styles.List}>
            <li data-testid='reset-account-list-first-element'>
              <p>
                Your profile will be permanently deleted, including information gathered during verifications.
              </p>
            </li>
            <li data-testid='reset-account-list-second-element'>
              <p>Deleting your account cannot be undone.</p>
            </li>
            <li data-testid='reset-account-list-second-element'>
              <p>
                You will need to contact service provider to set up <b>IDgo</b> again.
              </p>
            </li>
          </ul>
          <IonButton
            data-testid='reset-account-button'
            className={styles.IonButton}
            onClick={handleAlert}
            color='danger'
          >
            Delete Account
          </IonButton>
        </div>
      </IonContent>
      <IonAlert
        isOpen={showAlert}
        header={'Are you sure you want to delete your account?'}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              setShowAlert(false);
            },
          },
          {
            text: 'Okay',
            handler: handleReset,
          },
        ]}
      />
    </IonPage>
  );
}

export default ResetAccount;
