import React, { useEffect, useState } from 'react';
import 
{ IonPage
, IonContent
, IonText
, IonAccordionGroup
} from '@ionic/react';
import { useHistory } from 'react-router-dom';

//Styles
import styles from './RelyingPartyList.module.css';
import RelyingPartyItem from './RelyingPartyItem/RelyingPartyItem';

//Context
import { useRP } from '../../../context/RPContext';

//Utils
import { useToast } from '@agney/ir-toast';
import logger from '../../../utils/logger';
import Header from '../../layout/Header/Header';
import { getRelyingPartyList, enableRelyingPartyVerifications } from '../../../utils/services';

function RelyingPartyList(props) {
  const history = useHistory();
  const Toast = useToast();
  const { setSubdomain } = useRP();
  const [ relyingPartyList, setRelyingPartyList ] = useState();
  const [ refreshTimestamp, setRefreshTimestamp ] = useState();
  const [ signinRequest, setSigninRequest ] = useState();

  useEffect(() => {
    setSigninRequest(props?.history?.location?.state?.signinRequest);
    if (!props?.history?.location?.state?.refreshTimestamp) return;
    setRefreshTimestamp(props.history.location.state.refreshTimestamp);
  }, [props.history.location]);

  useEffect(() => {
    async function fetchRelyingParties () {
      const response = await getRelyingPartyList();
      if (response.status !== 200) return;
      setRelyingPartyList(response.data.result);
    }
    fetchRelyingParties();
  }, [refreshTimestamp]);

  const selectSubdomain = (relyingPartyInfo) => {
    setSubdomain(relyingPartyInfo.subdomain);
    history.replace({ pathname:'/dashboard', state:{ signinRequest }});
  };

  const toggleVerifications = async (subdomain, relyingPartyName, enableVerifications) => {
    await enableRelyingPartyVerifications(subdomain, enableVerifications);
    const toggle = (enableVerifications) ? 'Enabled' : 'Disabled';
    const color = (enableVerifications) ? 'success' : 'warning';
    const msg = `Authentication requests have been "${toggle}" for ${relyingPartyName}`;
    Toast.create({color: color, message: msg, duration: 2000, position: 'top'}).present();
    logger.event('verification-toggled', subdomain);
  };

  return (
    <IonPage>
      <Header page='IDgo Network' menuButton={true} />
      <IonContent className='ion-padding' id={styles.ContentRelyingPartyList}>
        <IonText className={styles.ListHeader}><h2>Your <b>IDgo</b> network</h2></IonText>

        <IonAccordionGroup>
          {relyingPartyList && relyingPartyList.map((rp) => (
            <RelyingPartyItem
              key={rp.id}
              relyingParty={rp} 
              selectSubdomain={selectSubdomain}
              toggleVerifications={toggleVerifications}
            />
          ))}
        </IonAccordionGroup>
      </IonContent>
    </IonPage>
  );
}

export default RelyingPartyList;
