import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import
{ IonPage
, IonImg
, IonContent
, IonText
, IonButton
, useIonAlert
, IonAlert
} from '@ionic/react';

//Context
import { useFIDO } from '../../../context/FIDOContext';
import { useRP } from "../../../context/RPContext";

//Styles
import styles from './Fido.module.css';

//Utils
import axios from 'axios';
import logger from '../../../utils/logger';
import UAParser from 'ua-parser-js';
import { Buffer } from 'buffer';
import { ctxValue } from '../../../utils/config';
import { useToast } from '@agney/ir-toast';
import { startRegistration } from '@simplewebauthn/browser';
import { tokenVerify, setAccessToken } from '../../../utils/session';
import { isAuthenticatorAvailble } from '../../../utils/validateData'

//Services
import 
{ getSecurityKeyRegisterInfo
, securityKeyRegisterExists
, logSigninEvent
, registerIDgoAuthCookie
} from '../../../utils/services';

const userAgent = new UAParser();

function Fido(props) {
  const Toast = useToast();
  const history = useHistory();
  const [ showIonAlert ] = useIonAlert();
  const { skrKey } = useParams();
  const { setFIDO } = useFIDO();
  const { subdomain, setSubdomain, buttonStyles, setButtonStyles } = useRP();
  const [ styledButtonHover, setStyledButtonHover ] = useState(false);
  const [ styledButtonFocus, setStyledButtonFocus ] = useState(false);
  const [ deviceName, setDeviceName ] = useState();
  const [ skrRequestId, setSkrRequestId ] = useState();
  const [ skrInfoStatus, setSkrInfoStatus ] = useState();
  const [ processing, setProcessing ] = useState(false);
  const [ showKeychainAlert, setShowKeychainAlert ] = useState(false);
  const [ showNoBioAlert, setShowNoBioAlert ] = useState(false);
  const [ showSetupBioAlert, setShowSetupBioAlert ] = useState(false);
  const [ signinRequest, setSigninRequest ] = useState();
  const [ passkeyAttempt, setPasskeyAttempt ] = useState();

  useEffect(() => {
    if (!props?.history?.location?.state?.signinRequest) return;
    setSigninRequest(props.history.location.state.signinRequest);
    verifyAuthenticatorAvailable(props.history.location.state.signinRequest);
    // eslint-disable-next-line
  }, [props.history.location.state]);

  useEffect(() => {
    async function __getSecurityKeyRegisterInfo(skrKey) {
      const response = await getSecurityKeyRegisterInfo(skrKey);
      setSkrInfoStatus(response?.status);
      if (response?.status!==200 && response?.status!==404) {
        Toast.create({ message: response?.message, color: 'danger', position: 'top', duration: 5000}).present();
        return;
      }
      setSigninRequest({signinType: 'r', requestKey: skrKey});
      if (response?.status!==200) return;
      setSubdomain(response.data?.subdomain);
      setDeviceName(response.data?.deviceName);
      setSkrRequestId(response.data?.registerRequestId);
      setAccessToken(response.data.token, false);
      if (response.data?.buttonStyles) {
        setButtonStyles(response.data.buttonStyles);
      }
    }

    if (skrKey) {
      __getSecurityKeyRegisterInfo(skrKey);
      return;
    }

    const device = userAgent.getDevice();
    const os = userAgent.getOS();
    setDeviceName(`${device?.vendor} ${device?.model} OS ${os?.name}:${os?.version}`);
  }, [skrKey, Toast, setSubdomain, setButtonStyles]);

  const verifyAuthenticatorAvailable = async (signinEventInfo) => {
    const isAvailable = await isAuthenticatorAvailble();
    if (!isAvailable) {
      // called from the useEffect before the useState propagates the signinRequest value
      // use optional param 'signinEventInfo' when provided
      const eventInfo = signinEventInfo || signinRequest;
      await logSigninEvent(eventInfo, 20.00);
      setShowNoBioAlert(true);
    };
  };

  const registerDevice_No = async () => {
    await logSigninEvent(signinRequest, 20.21);
    navigateToDashboardOrRelyingPartyList();
  };

  const registerDevice_Yes = async () => {
    await logSigninEvent(signinRequest, 20.22);
    registerThisDevice();
  };

  const passkeyAttemptComplete = async (success, passkeyAttemptMessage) => {
    if (skrKey) {
      // If this was a Passkey Registeration (SKR) them simply mark as attempted and render the SKR result message
      setPasskeyAttempt({success, passkeyAttemptMessage});
      return;
    }
    // If failed to create a Passkey AND have a subdomain AND this RP is configured to support Auth Cookies, offer to Register This Device
    if (!success && subdomain && signinRequest?.canRegisterDevice === true) {
      await logSigninEvent(signinRequest, 20.20);
      showIonAlert({
        header: `Register this device?`,
        subHeader: `As an alternative to Passkeys, you can register this device.`,
        message: `Would you like to register this device to simplify future authentications and improve security?`,
        buttons: [ {text:'No' , handler: () => registerDevice_No()}
                 , {text:'Yes', handler: () => registerDevice_Yes()}
                 ]
      });
      return;
    }
    navigateToDashboardOrRelyingPartyList();
  };

  const registerThisDevice = async () => {
    const response = await registerIDgoAuthCookie(subdomain);
    if (response?.status===201) {
      await logSigninEvent(signinRequest, 20.23);
      Toast.create({ message: `Device registered!`, color: 'success', position: 'top', duration: 5000}).present();
    } else {
      await logSigninEvent(signinRequest, 20.24);
      Toast.create({ message: `Sorry, unable to register device.`, color: 'danger', position: 'top', duration: 5000}).present();
    }
    navigateToDashboardOrRelyingPartyList()
  };

  const navigateToDashboardOrRelyingPartyList = async () => {
    if (subdomain) {
      history.replace({pathname:'/dashboard', search: history.location.search, state:{ refreshTimestamp: new Date(), signinRequest }});
    } else {
      history.replace({pathname:'/relying-party-list', state:{ refreshTimestamp:new Date(), signinRequest }});
    }
  };

  // This function has both bubble messages going to listening IDgo Agent instances,
  // and logging to the database for post activity research

  const createPasskey = async () => {
    const token = tokenVerify();
    setProcessing(true); // disable button

    const identityService = ctxValue('IDENTITY_SERVICE');

    // GET registration options from the endpoint that calls
    // @simplewebauthn/server -> generateRegistrationOptions()
    const urlGRO = (subdomain)
      ? `${identityService}/webauth/generateRegistrationOptions/${subdomain}`
      : `${identityService}/webauth/generateRegistrationOptions`
      ;
    let regOptsResp;
    try {
      regOptsResp = await axios.get(
        urlGRO,
        { headers: { 'Authorization': `Bearer ${token}` }, withCredentials: true }
      );
    } catch (err) {
      await logSigninEvent(signinRequest, 20.01);
      logger.error(`Fido: Registration aborted due to generateRegistrationOptions() error - ${err}`);
      logger.event('fido-registeration-failure'); // post message to IDgo Agent
      Toast.create({color: 'tertiary', message: 'Unable to register device credentials at this time (1).', duration: 2000, position: 'top'}).present();
      passkeyAttemptComplete(false, `Passkey creation aborted due to options error`);
      setProcessing(false);
      return;
    }

    let registrationResp;
    const timerStart = Date.now();
    try {
      // Pass the options to the device authenticator and wait for a response
      registrationResp = await startRegistration(regOptsResp.data.regOptions);
    } catch (err) {
      const timerEnd = Date.now();
      const duration = timerEnd - timerStart;
      const eventDetails = { userAgent: {OS: userAgent.getOS()}, error: {name: err?.name, message: err?.message, code: err?.code, cause: `${err?.cause}`, duration}};
      await logSigninEvent(signinRequest, 20.02, eventDetails);

      if (err?.name === 'InvalidStateError') {
        // probably already registered
        logger.warn(`Fido: Registration aborted due to startRegistration(). Error name: '${err?.name}', message: ${err?.message}, code: ${err?.code}, cause: ${err?.cause}, duration: ${duration} ms`);
        if (err?.code==='ERROR_AUTHENTICATOR_PREVIOUSLY_REGISTERED') {
          Toast.create({color: 'success', message: 'A Passkey already exists for this device.', duration: 2500, position: 'top'}).present();
          if (skrRequestId) {
            // Update this Security Key Register request's status to 'exists'
            await securityKeyRegisterExists(skrRequestId);
          }
          // todo: consider making an API call to create a FIDO marker cookie for CX optimization
          passkeyAttemptComplete(true, `Passkey creation aborted - already exists`);
        } else {
          passkeyAttemptComplete(false, `Passkey creation aborted - invalid state error`);
        }
      } else if (err?.name === 'NotAllowedError') {
        logger.warn(`Fido: Registration aborted due to startRegistration(). Error name: '${err?.name}', message: ${err?.message}, code: ${err?.code}, cause: ${err?.cause}, duration: ${duration} ms`);
        // There are 3 known reasons for this error (1:user cancels, 2:keychain not enabled, 3:no passcode).
        // The typical duration on #3 (for iOS ver 16.2 or less) is 22 ms
        // #1 & #2 require user action and are typically over 1500 ms, #2 is triggered when the user clicks the "Turn on iCloud Keychain..." button.
        if (duration < 200) { // only works on iOS 16.2 or less
          // No passcode
          await logSigninEvent(signinRequest, 20.03);
          setShowNoBioAlert(true);
        } else {
          // User selected Cancel from the Platform Authenticator dialog or Keychain not enabled
          await logSigninEvent(signinRequest, 20.04);
          logger.event('fido-registeration-failure'); // post message to IDgo Agent
          setShowKeychainAlert(true);
        }
      } else {
        // random errors while webauthn is talking to the credential manager. Not bugs we can solve so warning only.
        logger.warn(`Fido: Registration aborted due to startRegistration(). Error name: '${err?.name}', message: ${err?.message}, code: ${err?.code}, cause: ${err?.cause}`);
        logger.event('fido-registeration-failure'); // post message to IDgo Agent
        Toast.create({color: 'warning', message: 'Device credentials not registered', duration: 1500, position: 'top'}).present();
        passkeyAttemptComplete(false, `Passkey creation aborted due to registration start error`);
      }

      setProcessing(false);
      return;
    }

    // POST the response to the endpoint that calls
    // @simplewebauthn/server -> verifyRegistrationResponse()
    let verificationResp;
    const urlVR = (skrRequestId)
      ? `${identityService}/webauth/verifyRegistration/${skrRequestId}/${Buffer.from(deviceName).toString('base64')}`
      : `${identityService}/webauth/verifyRegistration/${Buffer.from(deviceName).toString('base64')}`
      ;
    try {
      verificationResp = await axios.post(
        urlVR,
        registrationResp,
        { headers: { 'Content-type' : 'application/json', 'Authorization': `Bearer ${token}` } }
      );
    } catch (err) {
      await logSigninEvent(signinRequest, 20.05);
      logger.error(`Fido: Registration aborted due to verifyRegistration() error - ${err}`);
      logger.event('fido-registeration-failure'); // post message to IDgo Agent
      Toast.create({color: 'tertiary',message: 'Unable to register device credentials at this time (3).', duration: 2000, position: 'top'}).present();
      passkeyAttemptComplete(false, `Passkey creation aborted due to registration error`);
      setProcessing(false);
      return;
    }

    // Show UI appropriate for the `verified` status
    if (!verificationResp?.data?.verified) {
      await logSigninEvent(signinRequest, 20.06);
      logger.error("Fido: Registration aborted due 'verified=false'");
      logger.event('fido-registeration-failure'); // post message to IDgo Agent
      Toast.create({color: 'tertiary',message: 'Unable to register device credentials at this time (4).', duration: 2000, position: 'top'}).present();
      passkeyAttemptComplete(false, `Passkey creation aborted - not verified`);
      setProcessing(false);
      return;
    }

    // SUCCESS!
    await logSigninEvent(signinRequest, 20.07);
    logger.event('fido-registeration-success'); // post message to IDgo Agent
    setFIDO(true);
    passkeyAttemptComplete(true, `Passkey created!`);
    setProcessing(false);
  };

  const doNotCreatePasskey = async () => {
    await logSigninEvent(signinRequest, 20.001, {signinRequest});
    logger.event('fido-registeration-cancelled'); // post message to IDgo Agent
    passkeyAttemptComplete(false, `Passkey creation skipped by user choice`);
  };

  const renderExpiredRequest = () => {
    return <>
      <div className={styles.RequestExpired}>Sorry, this request has expired.</div>
    </>
  };

  const renderPasskeyAttemptResult = () => {
    if (passkeyAttempt?.success) {
      return <>
        <div className={styles.PasskeyAttemptSuccess}>
          {passkeyAttempt?.passkeyAttemptMessage}
        </div>
        <div className={styles.CloseThisWindow}>
          (This window or tab can now be closed)
        </div>
      </>
    } else {
      return <>
        <div className={styles.PasskeyAttemptFailed}>
          {passkeyAttempt?.passkeyAttemptMessage}
        </div>
        <div className={styles.CloseThisWindow}>
          (This window or tab can now be closed)
        </div>
      </>
    }
  };

  const renderEnterpriseInfo = () => {
    return <>
      <div className={styles.BluePanel}>
        <div className={styles.ContainerRelayingLogo}>
          <IonImg src={`https://mwlogos${ctxValue('LOGOENV').toLowerCase()}.z5.web.core.windows.net/${subdomain}.png?v3`} data-testid='id-go-logo' alt={`${subdomain}.png`} />
        </div>
        <IonText className={styles.ContainerText}>
          <h1>Would you like to create a Passkey?</h1>
          <h2>Creating a Passkey can simplify your authentication experience in the future by enabling device security such as biometrics instead of using an SMS one-time passcode.</h2>
        </IonText>
      </div>
    </>
  };

  const renderPasskeyButton = () => {
    return <>
      <IonButton
        className={styles.PasskeyButton}
        style={{
          ...buttonStyles['inline'],
          ...(styledButtonHover ? buttonStyles[':hover'] : null),
          ...(styledButtonFocus ? buttonStyles[':focus'] : null),
        }}
        onMouseEnter={()=>{setStyledButtonHover(true)}}
        onMouseLeave={()=>{setStyledButtonHover(false)}}
        onIonFocus={()=>{setStyledButtonFocus(true)}}
        onIonBlur={()=>{setStyledButtonFocus(false)}}
        onClick={createPasskey}
        expand='block'
        disabled={processing}
        type='submit'
      >
        CREATE PASSKEY
      </IonButton>
    </>
  };
  const renderDoNotCreateButton = () => {
    return <>
      <IonButton
        className={styles.NoPasskeyButton}
        style={{
          ...buttonStyles['inline-secondary'],
          ...(styledButtonHover ? buttonStyles[':hover-secondary'] : null),
          ...(styledButtonFocus ? buttonStyles[':focus-secondary'] : null),
        }}
        onClick={doNotCreatePasskey}
        expand='block'
        disabled={processing}
        type='submit'
      >
        DO NOT CREATE PASSKEY
      </IonButton>
    </>
  };

  const renderCreateSecurityKeyContent = () => {
    if (skrKey) {
      if (passkeyAttempt) {
        return renderPasskeyAttemptResult();
      }
      // render Create Passkey button only
      return <>
      	<div className={styles.FidoPage}>
	        <div className={styles.Buttons}>
	          { renderPasskeyButton() }
	        </div>
        </div>
      </>
    }

    // render the full IDgo mobile web screen with 2 buttons
    return <>
      <div className={styles.FidoPage}>
        { renderEnterpriseInfo() }
        <div className={styles.Buttons}>
          { renderPasskeyButton() }
          { renderDoNotCreateButton() }
        </div>
      </div>
    </>
  };

  const showKeychainAlert_No = async () => {
    await logSigninEvent(signinRequest, 20.08);
    passkeyAttemptComplete(false, `Passkey request cancelled - Keychain not enabled`);
  };

  const showKeychainAlert_Yes = async () => {
    await logSigninEvent(signinRequest, 20.09);
    setShowKeychainAlert(false);
  };

  const showNoBioAlert_No = async () => {
    await logSigninEvent(signinRequest, 20.10);
    logger.info('Fido: Chose to bypass biometrics authentication recommendation');
    logger.event('fido-registeration-cancelled'); // post message to IDgo Agent
    passkeyAttemptComplete(false, `Passkey request cancelled - Not configured`);
  };

  const showNoBioAlert_Yes = async () => {
    await logSigninEvent(signinRequest, 20.11);
    logger.info('Fido: Prompted to setup platform authenticator');
    setShowSetupBioAlert(true) 
  };

  const renderWithExpiredCheck = () => {
    if (skrKey) {
      if (skrInfoStatus===404) return renderExpiredRequest();
      if (skrInfoStatus!==200) return <></>;
    }
    return renderCreateSecurityKeyContent();
  };

  const renderPage = () => {
    return <>
      <IonPage>
        <IonContent className='ion-padding' id={styles.IonContent}>
          { renderWithExpiredCheck() }
        </IonContent>

        <IonAlert
          isOpen={showKeychainAlert}
          onDidDismiss={() => setShowKeychainAlert(false)}
          header={'Passkey Not Created'}
          message={'If you are an iPhone user and just turned-on iCloud Passwords & Keychain, please select Yes.</br></br>Create Passkey?'}
          buttons={[
            {
              text: 'No',
              role: 'cancel',
              handler: () => { showKeychainAlert_No() }
            },
            {
              text: 'Yes',
              handler: () => { showKeychainAlert_Yes() }
            },
          ]}
        />

        <IonAlert
          isOpen={showNoBioAlert}
          onDidDismiss={() => setShowNoBioAlert(false)}
          header={'Biometrics not setup'}
          message={'Face ID, Touch ID/Fingerprint or Passcode are not setup. It is recommended to setup for improved security.</br></br>Would you like to set them up now?'}
          buttons={[
            {
              text: 'No',
              role: 'cancel',
              handler: () => { showNoBioAlert_No(); }
            },
            {
              text: 'Yes',
              handler: () => { showNoBioAlert_Yes() }
            },
          ]}
        />

        <IonAlert
          isOpen={showSetupBioAlert}
          onDidDismiss={() => setShowSetupBioAlert(false)}
          header={'Setup Biometrics'}
          message={'Proceed to your device settings to configure Face ID, Touch ID/Fingerprint, or Passcode. When finished come back and click on Done below.'}
          buttons={[
            {
              text: 'Done',
              handler: () => { verifyAuthenticatorAvailable() }
            }
          ]}
        />

      </IonPage>
    </>
  };

  return (renderPage());
}

export default Fido;