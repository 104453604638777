let session = undefined;

export const setAccessToken = (token, usedFido) => {
  session = {token:token, usedFido:usedFido};
};

export const logout = () => {
  session = undefined;
};

// simple function for Routes to determine if we have a session.
export const haveSession = () => {
  const token = tokenVerify();
  if (!token) return false;
  return true;
};

export const haveFidoSession = () => {
  if (!session) return false;
  return session.usedFido;
};

export const parseJWT = (token) => {
  try {
    return JSON.parse(window.atob(token.split(".")[1]));
  } catch(e) {
    return null;
  }   
};

export const tokenVerify = () => {
  if (!session) return;
  try {
    const decodedJWT = parseJWT(session.token);
    if(decodedJWT.exp * 1000 < Date.now()) {
      logout();
    } else {
      return session.token;
    }
  } catch (err) {
    console.error(`tokenVerify() error: ${err}`);
    logout();
  }
};